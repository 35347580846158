<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>
    <div
      style="max-width: 100%; margin: auto; overflow: hidden; padding: 1rem"
      id="body-content-area"
      :disabled="isPendingDelete"
    >
      <div v-if="isLoading"><Loading /></div>
      <div
        :class="{ visible: isLoading }"
        :style="{
          visibility: maxHeight > 0 ? 'visible' : 'hidden',
          overflow: 'hidden',
        }"
      >
        <div id="pagelayout">
          <div
            :class="{ visible: isLoading }"
            class="top-bar"
            style="width: 100%; margin-bottom: 0.5rem; margin-top: -1rem"
          >
            <div
              class="grid-header-controls"
              style="min-width: 100%; width: 100%; margin-top: 0.5rem"
            >
              <div style="float: left">

                <div class="level">
                  <div class="level-left">
                    <Select
                      v-if="institutions.length > 0"
                      :options="institutionsForSearch"
                      :defaultItem="selectedInst"
                      :placeholder="'Select Institution'"
                      :isDefault="false"
                      :maxItem="10000"
                      @selected="searchOnChange"
                    :inputStyle="{ height: '36px', marginTop: '1rem' }"
                    />
                  </div>
                </div>
              </div>
              <div style="float: right; margin-top: 1rem">
                <a
                  class="button is-accent"
                  @click="
                    !$hasPermissions(clientSession, ['USERS'], 2)
                      ? null
                      : getCsvExport()
                  "
                  :disabled="!$hasPermissions(clientSession, ['USERS'], 2)"
                >
                  <span class="icon"><i class="fal fa-file-csv"></i></span>
                  <span>Export Users</span>
                </a>
              </div>
              <div style="float: left; margin-left: 0.5rem; margin-top: 1rem">
                <a class="button is-light" @click="clearFilters">
                  <span>Clear Filters</span>
                </a>
              </div>
              <div style="float: right; margin-right: 0.5rem; margin-top: 1rem">
                <a
                  class="button is-accent"
                  @click="
                    $router.push({
                      name: !$hasPermissions(clientSession, ['USERS'], 2)
                        ? ''
                        : 'NewUser',
                      params: {},
                    })
                  "
                  :disabled="!$hasPermissions(clientSession, ['USERS'], 2)"
                >
                  <span class="icon">
                    <i class="fal fa-plus-square"></i>
                  </span>
                  <span>
                    <router-link
                      :to="{
                        name: !$hasPermissions(clientSession, ['USERS'], 2)
                          ? null
                          : 'NewUser',
                        params: {},
                      }"
                      class="has-text-white"
                    >
                      Add User
                    </router-link>
                  </span>
                </a>
              </div>
              <div style="float: right; margin-right: 0.5rem; margin-top: 1rem">
                <a
                  class="button is-dark"
                  style="min-width: 100px"
                  @click="confirmDeleteLocal"
                  :disabled="
                    !$hasPermissions(clientSession, ['USERS'], 2) ||
                    !hasSelection
                  "
                >
                  <span class="icon" v-if="!isPendingDelete">
                    <i class="fal fa-trash-alt"></i>
                  </span>
                  <span v-if="!isPendingDelete"
                    >Delete User{{ hasPluralSelection ? "s" : "" }}</span
                  >
                  <span v-if="isPendingDelete">
                    <span class="icon is-large">
                      <spin-loader />
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <Grid
            v-if="!isLoading"
            :style="{ height: maxHeight + 'px', maxHeight: maxHeight + 'px' }"
            :filter="filter"
            :data-items="institutionUsers"
            :selected-field="selectedField"
            :sortable="true"
            :sort="sort"
            :filterable="true"
            :pageable="{
              ...pageable,
              pageSizes: pageSizes,
            }"
            :page-size="pageSize"
            :skip="skip"
            :take="take"
            :total="totalRecords"
            :columns="columns"
            @selectionchange="onSelectionChange"
            @headerselectionchange="onHeaderSelectionChange"
            @filterchange="filterChangeHandler"
            @sortchange="sortChangeHandler"
            @pagechange="pageChangeHandler"
          >
            <template v-slot:emailTemplate="{ props, listeners }">
              <td>
                <router-link
                  class="bcc-color"
                  :to="{
                    name: 'EditUser',
                    params: {
                      id: `${props.dataItem.id}`,
                      model: `${JSON.stringify(props.dataItem)}`,
                      skip: `${skip}`,
                    },
                  }"
                >
                  {{ props.dataItem.email }}
                </router-link>
              </td>
            </template>
            <template v-slot:filterSlotTemplate="{ props, methods }">
              <div class="k-filtercell">
                <div class="k-filtercell-wrapper">
                  <input
                    type="text"
                    class="k-textbox"
                    :id="`${props.field}`"
                    :value="props.value"
                    @input="
                      (ev) => {
                        methods.change({
                          operator: 'contains',
                          field: props.field,
                          value: ev.target.value,
                          syntheticEvent: ev,
                        });
                      }
                    "
                  />
                </div>
              </div>
            </template>
          </Grid>
        </div>

        <!-- inform users to transfer things associated with user being deleted to another user  -->
        <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
          <div class="modal-background" @click="showDeleteModal = false"></div>
          <div class="modal-card">
            <header class="modal-card-head has-bg-danger">
              <div class="modal-card-title has-bg-danger">Confirm Delete</div>
              <a
                class="delete"
                aria-label="close"
                @click="showDeleteModal = false"
              ></a>
            </header>
            <section class="modal-card-body">
              <div class="field" style="margin-bottom: 5px">
                <div class="control">
                  <div v-if="deleteOptions.reportIds.length > 0">
                    <div style="margin-bottom: 10px">
                      {{
                        selectedRows.length > 1
                          ? "One or more of the selected user(s) have"
                          : "This user has"
                      }}
                      a total of {{ deleteOptions.reportIds.length }} reports
                      associated with their account(s). Please select a new
                      owner for these reports.
                    </div>
                    <div class="control" style="margin-bottom: 10px">
                      <div class="select">
                        <select v-model="reportsTargetUser" v-focus>
                          <option
                            v-for="opt in usersArr.filter(
                              (u) =>
                                !selectedRows.includes(u.id) &&
                                ['Staff', 'Manager'].includes(u.role) &&
                                deleteOptions.reportUsers.get(u.id)
                            )"
                            :key="opt.id"
                            :value="opt.id"
                          >
                            {{ opt.searchFieldName }}
                          </option>
                        </select>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div v-if="deleteOptions.documentIds.length > 0">
                    <div style="margin-bottom: 10px">
                      {{
                        selectedRows.length > 1
                          ? "One or more of the selected user(s) have"
                          : "This user has"
                      }}
                      a total of
                      {{ deleteOptions.documentIds.length }} documents
                      associated with their account(s). Please select a new
                      owner for these documents.
                    </div>
                    <div class="control" style="margin-bottom: 10px">
                      <div class="select">
                        <select v-model="documentsTargetUser" v-focus>
                          <option
                            v-for="opt in usersArr.filter(
                              (u) =>
                                !selectedRows.includes(u.id) &&
                                ['Staff', 'Manager'].includes(u.role) &&
                                deleteOptions.documentUsers.get(u.id)
                            )"
                            :key="opt.id"
                            :value="opt.id"
                          >
                            {{ opt.searchFieldName }}
                          </option>
                        </select>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div class="content" style="margin-bottom: 10px">
                    Type "<b>delete</b>" to confirm that you want to delete one
                    or more users.
                  </div>
                  <input
                    type="text"
                    class="input"
                    placeholder="type `delete` and then click confirm"
                    v-model.trim="confirmationText"
                    @keydown.enter.prevent="deleteIfConfirmed"
                  />
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <a
                class="button is-danger"
                @click="deleteIfConfirmed"
                :disabled="
                  confirmationText !== 'delete' ||
                  (deleteOptions.reportIds.length > 0 &&
                    reportsTargetUser === 0) ||
                  (deleteOptions.documentIds.length > 0 &&
                    documentsTargetUser === 0)
                "
              >
                <span class="icon">
                  <i class="fal fa-trash-alt"></i>
                </span>
                <span>Confirm</span>
              </a>
              <a class="button" @click="showDeleteModal = false">Cancel</a>
            </footer>
          </div>
        </div>
      </div>

      <div v-if="maxHeight === 0">
        <Loading />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import Papa from 'papaparse'
import Select from '@/components/partials/dropdowns/SearchableDropdown.vue'
let pageName = 'User Management'

// https://stackoverflow.com/a/1909508
function debounce (fn, ms) {
  let timer = 0
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

// https://stackoverflow.com/a/39983194
function downloadFile (fileName, urlData) {
  let aLink = document.createElement('a')
  aLink.download = fileName
  aLink.href = urlData
  aLink.setAttribute('target', '_blank')

  let event = new MouseEvent('click')
  aLink.dispatchEvent(event)
}

export default {
  components: {
    Loading,
    Grid: Grid,
    Select
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      searchValue: '',
      searchItemTemplate: '<strong>#= id #</strong> - #= name #',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      payload: {
        filter: {
          institutionId: 0
        },
        limit: 20,
        offset: 20
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'email',
          title: 'Email Address',
          filterable: true,
          cell: 'emailTemplate',
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'displayName',
          title: 'Name',
          filterable: true,
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'role',
          title: 'Role',
          filterable: true,
          filterCell: 'filterSlotTemplate'
        },
        {
          field: 'status',
          title: 'Status',
          filterable: true,
          filterCell: 'filterSlotTemplate',
          sortable: false
        }
      ],
      input: {
        email: '',
        name: '',
        role: null,
        status: '',
        isTeamMember: false
      },
      reportsTargetUser: 0,
      documentsTargetUser: 0,
      isPendingDelete: false,
      deleteOptions: [],
      institutions: [],
      users: [],
      usersArr: [],
      roles: ['Client', 'Outside Sales Rep', 'Staff', 'Manager'],
      statuses: ['Active', 'Locked'],
      isLoading: true,
      showFilters: false,
      showDeleteModal: false,
      confirmationText: '',
      selectedField: 'selected',
      selectedRows: [],
      selectedRowValue: true,
      skip: 0,
      take: 20,
      pageSize: 20,
      page: 1,
      totalRecords: 0,
      pageheading: pageName,
      sort: [{ field: 'displayName', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: []
      }
    }
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getUsers)
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    institutionsForSearch () {
      return [
        { id: 0, name: 'All Institutions' },
        ...this.institutions.map((inst) => {
          return { id: inst.id, name: inst.displayName }
        })
      ]
    },
    selectedInst () {
      if (
        this.searchValue != null &&
        this.institutions &&
        this.institutions.length > 0
      ) {
        let inst = this.institutions.find(
          (inst) => parseInt(inst.id) === parseInt(this.searchValue)
        )

        if (inst) {
          return { id: parseInt(this.searchValue), name: inst.displayName }
        }
      }
      return { id: 0, name: 'All Institutions' }
    },
    hasFilter () {
      let isFilterDefault =
        this.input.email === '' &&
        this.input.name === '' &&
        (this.input.role === null ||
          this.input.role === undefined ||
          this.input.role === '' ||
          this.input.role.toLowerCase() === 'select role') &&
        !this.input.isTeamMember
      return !isFilterDefault
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    institutionUsers: function () {
      if (this.users.length === 0) {
        return []
      }
      let filtered = filterBy(this.users, this.filter)
      return orderBy(filtered, this.sort)
    },
    columns () {
      return [
        {
          field: 'selected',
          width: '30px',
          filterable: false,
          headerSelectionValue: this.areAllSelected
        },
        ...this.staticColumns
      ]
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  async created () {
    if (localStorage.userSkip) {
      this.skip = JSON.parse(localStorage.getItem('userSkip'))
      localStorage.removeItem('userSkip')
    }

    if (localStorage.userFilters) {
      this.filters = JSON.parse(localStorage.getItem('userFilters'))
    }

    if (localStorage.userSort) {
      this.sort = JSON.parse(localStorage.getItem('userSort'))
    }

    if (localStorage.userInstitution) {
      this.searchValue = localStorage.getItem('userInstitution')
    }

    if (localStorage.userPageSize) {
      let pageSize = JSON.parse(localStorage.getItem('userPageSize'))
      this.take = pageSize
      this.perPage = pageSize
      this.pageSize = pageSize
    }

    // await this.getAllInstitutionsAsync()
    // await this.loadQueryParams(this.$route.query)
    // await this.checkPage(this.getUsersAsync)
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.top-bar',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight + 10

        await this.getAllInstitutionsAsync()
        await this.loadQueryParams()
        await this.getUsersAsync()
      }

      that.getListHeights(
        'body-content-area',
        40,
        79 + 43.8,
        elements,
        func.bind(this)
      )

      window.addEventListener('resize', () => {
        that.getListHeights(
          'body-content-area',
          40,
          79 + 43.8,
          elements,
          func.bind(that)
        )
      })
    })
  },
  updated () {
    // Manually repopulate filter values after server-side querying
    if (this.filters) {
      this.filters.forEach((element) => {
        var filterInput = document.getElementById(element.field)
        if (filterInput) {
          filterInput.value = element.value
        }
      })
    }
  },
  methods: {
    async getUserLookup () {
      try {
        let response = await this.axios.get(`/users/`, {
          filter: {}
        })

        this.usersArr = response.data.records.map((x) => {
          x.searchFieldName = `${x.displayName}  (${x.email})`
          return x
        })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    loadQueryParams (query) {
      let reload = false
      return reload
    },
    async getSearchQuery () {
      this.payload.limit = this.perPage || 20
      this.payload.offset = this.skip || 0

      this.payload.filter.institutionId = 0
      if (this.searchValue) {
        this.payload.filter.institutionId = this.searchValue
      }

      if (this.filters) {
        this.payload.filter.gridFilters = this.filters
      } else {
        this.payload.filter.gridFilters = []
      }

      if (this.sort.length) {
        this.payload.sort = this.sort[0].field + ' ' + this.sort[0].dir
      }
    },
    async getCsvExport () {
      let response = await this.axios.post(`/users/export`, this.payload)
      let csv = Papa.unparse(response.data, { skipEmptyLines: true })
      downloadFile(
        'users.csv',
        'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv)
      )
    },
    async getUsersAsync (isInitialLoad = true) {
      // TODO dedupe
      try {
        if (isInitialLoad) {
          this.isLoading = true
        }

        let response = {}
        let route = `/users/search/`
        await this.getSearchQuery()

        response = await this.axios.post(route, this.payload)
        this.totalRecords = response.data.page.totalRecords
        this.page = response.data.page.page

        let filteredReports = filterBy(response.data.records, this.filter)
        if (filteredReports) {
          filteredReports = filteredReports.map((item) => {
            return { ...item, selected: false }
          })
        }
        this.users = orderBy(filteredReports, this.sort)

        const list = response.data.records || response.data || []
        if (list.length > 0) {
          this.users = list.map(function (item) {
            return {
              ...item,
              selected: false,
              status: item.isLockedOut ? 'Locked' : 'Active'
            }
          })
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async confirmDeleteLocal () {
      if (!this.$hasPermissions(this.clientSession, ['USERS'], 2)) return

      this.isPendingDelete = true
      this.deleteOptions.reportIds = []
      this.deleteOptions.documentIds = []

      let isCurrentUser = false

      this.selectedRows.map((_row) => {
        if (_row === this.clientSession.userId) {
          isCurrentUser = true
        }
      })

      if (isCurrentUser) {
        let selectedRows = []
        this.selectedRows.map((_row) => {
          if (_row !== this.clientSession.userId) {
            selectedRows.push(_row)
          }
        })

        this.selectedRows = []
        this.selectedRows = selectedRows

        this.$forceUpdate()
        this.errorToast('You cannot delete your own account.')

        let func = () => {
          this.isLoading = false
        }

        setTimeout(func, 1000)
        return
      }

      try {
        let response = await this.axios.options('/users', {
          params: { ids: this.selectedRows }
        })

        this.deleteOptions = {
          ...response.data,
          reportUsers: new Map(
            response.data.reassignable.reportUsers.map((u) => [u, u])
          ),
          documentUsers: new Map(
            response.data.reassignable.documentUsers.map((u) => [u, u])
          )
        }

        // console.log(response.data.reassignable, this.deleteOptions)

        // console.log(this.deleteOptions.reportIds)

        if (!this.deleteOptions.documentIds) {
          this.deleteOptions.documentIds = []
        }

        // Only grab user records when we have selected some users to delete
        if (
          this.deleteOptions.reportIds.length > 0 ||
          this.deleteOptions.documentIds.length > 0
        ) { await this.getUserLookup() }

        this.showDeleteModal = true
      } catch (err) {
        this.handleApiErr(err)
      }

      this.isPendingDelete = false
    },
    deleteIfConfirmed (event) {
      if (this.confirmationText === 'delete') this.deleteSelectedLocal()
    },
    async deleteSelectedLocal () {
      this.showDeleteModal = false
      this.confirmationText = ''

      try {
        await this.$http.delete('/users', {
          data: {
            deleteUserIds: this.selectedRows,
            transfer: {
              reportUserId: this.reportsTargetUser,
              documentUserId: this.documentsTargetUser
            }
          }
        })

        await this.getUsersAsync()

        this.successToast('Users successfully deleted.')
      } catch (err) {
        this.handleApiErr(err)
      }
    },
    onHeaderSelectionChange (event) {
      this.users = this.users.map((item) => {
        return { ...item, selected: event.event.target.checked }
      })
      this.selectedRows = []

      if (event.event.target.checked) {
        this.selectedRows = this.users
          .filter((user) => user.selected === true)
          .map((user) => user.id)
      }
    },
    onSelectionChange (event) {
      if (event.event.target.checked) {
        this.selectedRows.push(event.dataItem.id)
      } else {
        const index = this.selectedRows.indexOf(event.dataItem.id)

        if (index > -1) this.selectedRows.splice(index, 1)
      }

      Vue.set(
        event.dataItem,
        this.selectedField,
        event.dataItem[this.selectedField]
      )
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
      this.perPage = event.page.take
      this.pageSize = event.page.take

      localStorage.setItem('userPageSize', event.page.take)

      this.getUsersAsync(false)
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, {
        [key]: value
      })
      this.pageable = newPageableState
    },
    changeHandler: (type, value) => this.updatePagerState(type, value),
    sortChangeHandler: function (e) {
      this.sort = e.sort
      localStorage.setItem('userSort', JSON.stringify(this.sort))
      this.getUsersAsync(false)
    },
    filterChangeHandler: debounce(function (e) {
      if (e.filter && !this.filters) {
        this.filters = e.filter.filters
      } else if (e.filter && this.filters) {
        e.filter.filters.forEach((element) => {
          this.filters.replaceOrPush(element, function (e) {
            return e.field === element.field
          })
        })
      } else {
        if (e.event.value === '') {
          this.filters = this.filters.filter((x) => x.field !== e.event.field)
        }
      }

      localStorage.setItem('userFilters', JSON.stringify(this.filters))

      this.skip = 0
      this.getUsersAsync(false)
    }, 500),
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach((element) => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) {
            filterInput.value = ''
          }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [{ field: 'displayName', dir: 'asc' }]

      localStorage.removeItem('userFilters')
      localStorage.removeItem('userSort')

      this.getUsersAsync(false)
    },
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    },
    searchOnChange: async function (e) {
      this.searchValue = e.id
      localStorage.setItem('userInstitution', this.searchValue)
      await this.getUsersAsync(false)
    },
    async getAllInstitutionsAsync () {
      try {
        let response = {}
        response = await this.axios.get('/institutions/all', {
          params: { page: 1, perPage: 10000 }
        })
        let q = { query: { page: 1 } }
        this.$router.push(q).catch((err) => {
          this.handleApiErr(err, null, null, true)
        })
        const list = response.data || []
        if (list.length > 0) {
          this.institutions = list.map(function (item) {
            return {
              id: item.id,
              name: item.name,
              displayName: item.id + ' - ' + item.name,
              city: item.city,
              stateCode: item.stateCode,
              teamMemberId1: item.teamMemberId1,
              teamMemberId2: item.teamMemberId2,
              teamMemberId3: item.teamMemberId3
            }
          })
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        // TODO WL this call is nested in load of view so is loading will be handled by view load
        // this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th {
  font-weight: 600 !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #cbe3b3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected {
  background-color: #7fb942;
}
.k-list .k-item.k-state-selected:hover {
  background-color: #cbe3b3;
  color: #0d0c0c;
}
.bcc-color {
  color: #7fb942 !important;
}
.grid-header-controls {
  display: inline-block;
}
.k-grid td:first-child {
  color: transparent !important;
}

.k-grid th:first-child {
  color: transparent !important;
}

.visible {
  visibility: hidden;
}

.k-dropdown {
  width: auto !important;
  min-width: 200px;
}

.k-master-row > td:first-child {
  color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
